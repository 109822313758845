<template>
    <div
    >
        <script type="application/javascript" defer src="https://player.twitch.tv/js/embed/v1.js" ref="twitchScript"></script>


        <!-- Lgame NavBar -->
        <div>
            <LgameNavBar></LgameNavBar>
        </div>
       
        <div class="main-page-wrapper">

            <div class="main-page-landing-area d-flex flex-column justify-content-between align-items-center position-relative pt-3 pt-lg-4 pb-5 w-100 h-100"
                :style="{
                    backgroundImage: 'url('+require('@/assets/hero-bg-2nd-edition.webp')+')',
                }"
            >   <div class="logos-container d-flex justify-content-between align-items-center px-2 px-lg-4 w-100">
                    <div class="pubg-logo-container p-2">
                        <img :src="require('@/assets/pubg-logo.png')" width="80"/>
                    </div>
                    <div class="mi-logo-container p-1 p-lg-3">
                        <img :src="require('@/assets/mi.png')" width="72"/>
                    </div>
                </div>
                <div class="landing-content d-flex flex-column justify-content-center align-items-center w-100 container mt-n5 mb-5 px-0">
                    <div class="card-participate mt-4">
                        <ParticipateCard
                            @init-pop-up="initPopUp"
                            @view-bracket="viewBracket"
                            :outlined="true"
                        ></ParticipateCard>
                    </div>
                </div>
            </div>

            <div class="how-to-participate-container my-3 my-lg-5 p-lg-0 py-4 w-100">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center container">
                    <div class="position-relative p-0 m-0">
                            <h2 class="section-title text-left" dir="auto"
                            >Comment participer / <span class="arabic-title">كيفية المشاركة</span></h2>
                    </div>
                   <!-- <div class="position-relative p-0 m-0">
                            <h2 class="section-title arabic-title text-left" dir="rtl"
                            >كيفية المشاركة</h2>
                    </div>-->
                </div>
                <div class="how-to-content d-flex position-relative  my-lg-4  m-0 w-100"   
                >
                    <div class="how-to-container container">
                        <div class="how-to-text-container pr-0 pr-lg-3 w-75">
                            <p class="text-justify how-to-text">
                                Venez mettre en avant vos talents en participant à notre tournoi PUBG Mobile conçu spécialement pour vous. <br>
                                Pour participer au tournoi, inscrivez-vous sur la plateforme <a class="m-0" href="https://lgame.gg" style="color:var(--main-color)">lgame.gg</a>, puis formez votre équipe et rejoignez le tournoi pour profiter de l'expérience compétitive unique offerte par Xiaomi. 
                            </p>
                            <br>
                            <p class="text-justify how-to-text-ar" dir="rtl">
                                اعرض مواهبك وشارك في بطولة PUBG Mobile الخاصة بنا المصممة خصيصًا لك.<br>
                                للمشاركة في البطولة، قم بالتسجيل على منصة <a class="m-0" href="https://lgame.gg" style="color:var(--main-color)">lgame.gg</a>، ثم شكل فريقك وانضم إلى البطولة للإستمتاع بالتجربة التنافسية الفريدة من نوعها المقدمة لك من طرف Xiaomi.
                            </p>
                            <div class="card-participate d-flex flex-column-reverse flex-lg-row justify-content-end align-items-center mr-4 w-100">
                                <div class="mr-lg-5">
                                    <a class="how-to m-0" href="#faq" dir="rtl">كيفية المشاركة (من هنا)</a>
                                </div>
                                <ParticipateCard class="mr-lg-4"
                                    @init-pop-up="initPopUp"
                                    @view-bracket="viewBracket"
                                ></ParticipateCard>
                            </div>
                        </div>  
                    </div>
                    <div class="phone-art-container position-absolute">
                        <img :src="require('@/assets/phone-art.webp')" height="360px" width="440" />
                    </div> 
                </div>
            </div>

            <div class="prizes-container container my-3 my-lg-5 p-lg-0 py-5 w-100">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div class="position-relative p-0 m-0">
                            <h2 class="section-title text-left"
                            >Prix / <span class="arabic-title">الجوائز </span></h2>
                    </div>
                    <!--<div class="prizes-header-ar position-relative p-0 m-0">
                            <h2 class="section-title arabic-title  text-left" dir="rtl"
                            >الجوائز </h2>
                    </div>-->
                </div>
                <div class="py-lg-4">
                    <PrizesCards/>
                </div>
            </div>    
            <div class="container my-3 my-lg-5 p-lg-0 pb-4 w-100">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div class="position-relative p-0 m-0">
                            <h2 class="section-title text-left"
                            >Découvrez nos produits / <span class="arabic-title">اكتشفوا منتجاتنا </span></h2>
                    </div>
                    <!--<div class="prizes-header-ar position-relative p-0 m-0">
                            <h2 class="section-title arabic-title  text-left" dir="rtl"
                            >اكتشفوا منتجاتنا </h2>
                    </div>-->
                </div>
                <div class="products container w-100">
                    <ProductsList></ProductsList>
                </div>
                <!--<div class="view-more-container container mt-2 mb-3 w-100">
                    <BannerCard></BannerCard>
                </div> -->
            </div>
           

            <div class="bracket-container container my-4 my-lg-5 p-lg-0 py-4 w-100" id="bracket">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-2">
                    <div class="position-relative p-0 m-0">
                            <h2 class="section-title text-left"
                            >Bracket / <span class="arabic-title">النتائج</span></h2>
                    </div>
                    <!--<div class="position-relative p-0 m-0">
                            <h2 class="section-title arabic-title  text-left" dir="rtl"
                            >النتائج</h2>
                    </div>-->
                </div>
                <div class="bracket d-flex justify-content-center align-items-center"
                >
                    <BracketViewer></BracketViewer>
                </div>
            </div> 

            <div class="stream-container container my-3 my-lg-5 p-lg-0 py-4 w-100" id="stream">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-2">
                        <div class="position-relative p-0 m-0">
                                <h2 class="section-title text-left"
                                >Stream / <span class="arabic-title">البث المباشر</span></h2>
                        </div>
                        <!--<div class="position-relative p-0 m-0">
                                <h2 class="section-title arabic-title  text-left" dir="rtl"
                                >البث المباشر</h2>
                        </div>-->
                    </div>
                <div class="stream" ref="twitchPlayer"></div>
            </div>
        
        <div class="faq-container container my-3 my-lg-5 py-4 w-100">
            <FaqList/>
        </div>
        <div class="krafton-copyright-footer-container d-flex justify-content-center align-items-center  p-5 w-100" 
        >
            <div class="krafton-copyright-footer d-flex flex-column  justify-content-center align-items-center container w-100">
                <div class="d-flex flex-column justify-content-center align-items-end  p-0 mb-5 w-100 w-lg-75">
                    <div class="my-2">
                        <h3 class="contact-title text-justify">Contactez nous</h3>
                        <p class="contact-text  text-justify">
                            En cas de questions supplémentaires, vous <br class="br" v-if="!isMobile">pouvez nous contacter sur notre chaîne Discord.
                        </p>
                    </div>
                    <div class="w-75">
                        <h3 class="contact-title-ar text-justify" dir="rtl">للتواصل معنا</h3>
                        <p class="contact-text-ar text-justify" dir="rtl">
                            في حال وجود أي أسئلة إضافية، يمكنكم التواصل <br>معنا عبر
                            قناة Discord  الخاصة بنا 
                        </p>
                    </div>
                    <div class="my-2">
                        <iframe src="https://discord.com/widget?id=1134966955779039293&theme=dark" height="260" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                        </iframe>
                    </div>
                </div>
                <div class="krafton-logos d-flex justify-content-center align-items-center mt-5">
                    <img :src="require('@/assets/krafton-footer.png')" height="100"/>
                </div>
                
            </div>
        </div>
        
        <!-- lgame footer -->
        <LgameFooter></LgameFooter>

        <!-- Lgame participate pop up-->
        <LgameParticipatePopUp
            :pop-up-type="popUpOptions.type"
            :title="popUpOptions.title"
            :pop-up-message-body="popUpOptions.message"
            :with-options="popUpOptions.withOptions"
            :options="popUpOptions.options"
            v-if="showPopUp"
            @modal-closed="abortAction"
            @acceptAction="acceptAction"
            @abortAction="abortAction"
        ></LgameParticipatePopUp>
        </div>
    </div>
</template>

<script>
    import BracketViewer from './BracketViewer.vue';
    import ParticipateCard from './ParticipateCard.vue';
    import LgameFooter from './LgameFooter.vue';
    import LgameNavBar from './NavBar.vue';
    import LgameParticipatePopUp from './LgameParticipatePopUp.vue';
    import ProductsList from './ProductsList.vue';
    //import BannerCard from './Cards/BannerCard.vue';
    import FaqList from './FAQ/FaqList.vue';
    import PrizesCards from './Prizes/PrizesCards.vue';

    export default{
        name: "MainPage",
        components:{
            ParticipateCard,
            BracketViewer,
            LgameParticipatePopUp,
            LgameNavBar,
            LgameFooter,
            ProductsList,
            //BannerCard,
            FaqList,
            PrizesCards
        },
        data(){
            return{
                twitchPlayer: null,
                playerLoaded: false,

                showPopUp: false,
                popUpOptions: {
                    type: '',
                    title: '',
                    message: '',
                },
                isMobile: false,
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.currentTournament;
                },
                set(val) {
                    return val;
                }
            },
            currentTournament: {
                get() {
                    return this.$store.state.currentTournament.tournamentItem ? this.$store.state.currentTournament.tournamentItem : null;
                }
            },
        },
        methods: {
            initPlayer(){
                // eslint-disable-next-line
                this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                    width: '100%',
                    height: '100%',
                    channel: 'geekmaroctv',
                });
                this.twitchPlayer.setVolume(0.5);
                this.playerLoaded = true
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: true,
                    })*/
                });
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {
                    
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: false,
                    })*/
                });   
            },
            acceptAction(payload) {
                this.showPopUp = false;
                
                if (payload && payload.hasCustomFields) {
                    if (payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId,
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        this.$store.dispatch(this.popUpOptions.popUpDispatch, {
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                } else {
                    if (payload && payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        console.log('payload from pop up action ================= ')
                        console.log(payload)
                        console.log('payload from pop up action ================= ')
                        if (this.popUpOptions.dispatchData) {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        } else {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                                this.$store.dispatch('currentTournament/triggerLoading', false);
                            });
                        }
                    }
                }
            },
            abortAction() {
                this.showPopUp = false;
                this.$store.dispatch('currentTournament/triggerLoading', false);
            },
            initPopUp(options) {
                this.popUpOptions = options;
                this.showPopUp = true;
            },
            viewBracket(){
                this.$refs.bracketTrigger.click();
            },
        },
        created(){
            this.$store.dispatch('currentUser/authCheck').then(response => {
                if (response.success) {
                    this.$store.dispatch('currentUser/setCurrentUser');
                }
            });
            this.$store.dispatch('currentTournament/getTournament', 149);
        },
        mounted() {
            const resizeOps = () => {
                document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
                this.isMobile = window.innerWidth <= 768 ? true : false;
            };

            resizeOps();
            window.addEventListener("resize", resizeOps);

            this.$refs['twitchScript'].onload = () => {
                this.initPlayer();
            }
        }
    }

</script>

<style scoped>
.main-page-wrapper{
    background-color: #F2F2F2;
}
.prizes-header-ar{
    z-index:2;
}

.main-page-landing-area{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 63px
}
.how-to-container{
    z-index: 1;
}
.phone-art-container{
    bottom: 0;
    right: 0;
    z-index: 0;
}
.section-title{
    color:var(--main-color) !important;
    font-family: 'Roboto', sans-serif;
    font-size: 28pt;
}
.arabic-title{
    font-family: 'Tajawal', sans-serif !important;
    z-index:5;
    font-weight: 600;
}
.how-to-text, .how-to-text-ar{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #707070;
    font-size: 14pt;
}
.how-to-text-ar{
    font-family:"Tajawal" !important;
}

.how-to{
    color: var(--main-color);
    font-family: "Tajawal";
    font-weight: 600;
    text-decoration: underline;
    font-size: 18pt;
}
.stream{
    height: 860px;
}
.bracket{
    height: 80vh;
    background-color: #7668D9;
    overflow: hidden;
}

.prize-title{
    color: var(--main-color);
    text-transform: uppercase;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 16pt;
    line-height: 100%;
}
.prize-card{
    filter: drop-shadow(2px 2px 4px rgba(0, 0,0,0.1));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: white;
}


.krafton-copyright-footer-container{
    background-repeat: no-repeat;;
    background-image:url('@/assets/footer.webp') !important;
    background-size: cover;
    background-position: bottom right;
    
}
.krafton-copyright-footer{
    
    
}
.contact-title{
    color: var(--main-color);
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}
.contact-text{
    color: #707070;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}
.contact-title-ar{
    color: var(--main-color);
    font-weight: 600;
    font-family: "Tajawal" !important;
}
.contact-text-ar{
    color:#707070;
    font-weight: 600;
    font-family: "Tajawal" !important;
}
.krafton-logos{
    
    filter: drop-shadow(2px 2px 2px rgba(0, 0,0,0.2));
}
@media screen and (max-width: 800px){
    
}

@media screen and (max-width: 768px) {
    .br{
        visibility: hidden;
    }
    .main-page-landing-area{
        margin-top: 58px;
        background-image: url('@/assets/hero-bg-mob-2nd-edition.webp') !important;
    }
    .pubg-logo-container img{
        width: 56px;
    }
    .mi-logo-container img{
        width: 46px;
    }
    .phone-art-container{
        bottom: -200px;
    }
    .phone-art-container img{
        width: 210px !important;
        height: unset;
    }
    .how-to-text-container{
        width: 100% !important;
    }
    .how-to{
        font-size: 14pt;
        
    }
    .krafton-copyright-footer img{
        height: 60px !important;
    }
    .main-page-landing-area{
    /*background-image:url('@/assets/hero-mobile-bg.png') !important;*/
}

    .section-title{
        font-size: 18pt;
    }
    .how-to-content{
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    .stream{
        width: 100% !important;
        height: 360px;
    }
    
}
</style>