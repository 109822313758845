<template>
    <div class="prizes-wrapper d-flex flex-column flex-lg-row justify-content-center align-items-center w-100">
        <div class="prize-card second-prize-card position-relative mr-0 mr-lg-n2 my-2 my-lg-0 order-2 order-lg-1">
            <div class="medal-container position-absolute">
                <img :src="require('@/assets/2nd-place-medal.png')" height="56"/>
            </div>
            <div class="product-item d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <div class="p-2">
                    <img src='/imgs/redmi-note-13.png' height="170px"/>
                </div>
                <div class="p-2 my-2">
                    <h4 class="product-name reverse-color">Redmi note 13</h4>
                </div>
            </div>
            <div class="position-container">
                <h2 class="m-0">2nd</h2>
            </div>
            <div class="amount-container text-center px-2">
                <h5 class="m-0">x4</h5>
            </div>
        </div>
        <div class="prize-card first-prize-card position-relative order-1 order-lg-2">
            <div class="medal-container position-absolute">
                <img :src="require('@/assets/1st-place-medal.png')" height="64"/>
            </div>
            <div class="product-item d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <div class="p-2">
                    <img src='/imgs/redmi-note-13pro.png' width="160"/>
                </div>
                <div class="p-2 my-2">
                    <h3 class="product-name">Redmi Note 13 PRO+ 5G</h3>
                </div>
            </div>
            <div class="position-container">
                <h2 class="m-0">1st</h2>
            </div>
            <div class="amount-container text-center px-2">
                <h5 class="m-0">x4</h5>
            </div>
        </div>
        <div class="prize-card third-prize-card ml-lg-n2 position-relative order-3 order-lg-3">
            <div class="medal-container position-absolute">
                <img :src="require('@/assets/3rd-place-medal.png')" height="56"/>
            </div>
            <div class="product-item d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <div class="p-2">
                    <img src='/imgs/redmi-14c.png' height="170px"/>
                </div>
                <div class="p-2 my-2">
                    <h4 class="product-name reverse-color">Redmi 14C</h4>
                </div>
            </div>
            <div class="position-container">
                <h2 class="m-0">3rd</h2>
            </div>
            <div class="amount-container text-center px-2">
                <h5 class="m-0">x4</h5>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrizesCards"
    }
</script>

<style lang="css" scoped>
    .prize-card{
        border-radius: 16px;
        height: 360px;
        width: 300px;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.15);
        background-color: white;
    }
    .first-prize-card{
        background-color: var(--main-color) !important;
        height: 400px !important;
        z-index: 2
    }
    .first-prize-card .medal-container{
        position: absolute;
        top:0;
        left: 8px;    
    }
    .amount-container{
        position: absolute;
        bottom: 8px;
        left: 8px;
        background-color: white;
        border-radius: 0 8px 8px 8px;
    }
    .amount-container h5{
        color: var(--main-color) !important;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
    }
    .position-container{
        position: absolute;
        bottom:-8px;
        right:8px;
    }
    .position-container h2{
        line-height: 100%;
        color: white;
        opacity: 0.1;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
        font-size: 46pt;
    }
    .product-name{
        color: white;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
    }
    .reverse-color{
        color: var(--main-color);
    }
    .second-prize-card .medal-container{
        top:0;
        left: 8px;    
    }
    .second-prize-card .amount-container{
        bottom: 8px;
        background-color: var(--main-color);
    }
    .second-prize-card .amount-container h5{
        color: white !important;
    }
    .second-prize-card .position-container h2{
        color: var(--main-color);
    }
    .third-prize-card .medal-container{
        right: 8px;
        left:unset;
    }
    .third-prize-card .amount-container{
        background-color: var(--main-color);
        left: 16px !important;
    }
    .third-prize-card .amount-container h5{
        color: white !important;
    }
    .third-prize-card .position-container h2{
        color: var(--main-color);
    }
    @media only screen and (max-width: 600px) {
     
    }
</style>