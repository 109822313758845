var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prizes-wrapper d-flex flex-column flex-lg-row justify-content-center align-items-center w-100"},[_c('div',{staticClass:"prize-card second-prize-card position-relative mr-0 mr-lg-n2 my-2 my-lg-0 order-2 order-lg-1"},[_c('div',{staticClass:"medal-container position-absolute"},[_c('img',{attrs:{"src":require('@/assets/2nd-place-medal.png'),"height":"56"}})]),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"prize-card first-prize-card position-relative order-1 order-lg-2"},[_c('div',{staticClass:"medal-container position-absolute"},[_c('img',{attrs:{"src":require('@/assets/1st-place-medal.png'),"height":"64"}})]),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"prize-card third-prize-card ml-lg-n2 position-relative order-3 order-lg-3"},[_c('div',{staticClass:"medal-container position-absolute"},[_c('img',{attrs:{"src":require('@/assets/3rd-place-medal.png'),"height":"56"}})]),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item d-flex flex-column justify-content-center align-items-center w-100 h-100"},[_c('div',{staticClass:"p-2"},[_c('img',{attrs:{"src":"/imgs/redmi-note-13.png","height":"170px"}})]),_c('div',{staticClass:"p-2 my-2"},[_c('h4',{staticClass:"product-name reverse-color"},[_vm._v("Redmi note 13")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-container"},[_c('h2',{staticClass:"m-0"},[_vm._v("2nd")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amount-container text-center px-2"},[_c('h5',{staticClass:"m-0"},[_vm._v("x4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item d-flex flex-column justify-content-center align-items-center w-100 h-100"},[_c('div',{staticClass:"p-2"},[_c('img',{attrs:{"src":"/imgs/redmi-note-13pro.png","width":"160"}})]),_c('div',{staticClass:"p-2 my-2"},[_c('h3',{staticClass:"product-name"},[_vm._v("Redmi Note 13 PRO+ 5G")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-container"},[_c('h2',{staticClass:"m-0"},[_vm._v("1st")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amount-container text-center px-2"},[_c('h5',{staticClass:"m-0"},[_vm._v("x4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item d-flex flex-column justify-content-center align-items-center w-100 h-100"},[_c('div',{staticClass:"p-2"},[_c('img',{attrs:{"src":"/imgs/redmi-14c.png","height":"170px"}})]),_c('div',{staticClass:"p-2 my-2"},[_c('h4',{staticClass:"product-name reverse-color"},[_vm._v("Redmi 14C")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-container"},[_c('h2',{staticClass:"m-0"},[_vm._v("3rd")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amount-container text-center px-2"},[_c('h5',{staticClass:"m-0"},[_vm._v("x4")])])
}]

export { render, staticRenderFns }