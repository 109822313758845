<template>
    <div class="products-list d-flex">
        <product-card v-for="(product, i) in products"
            :name="product.name"
            :imageLink="product.image"
            :link="product.link"
            :key="i"
        ></product-card>
    </div>
</template>

<script>
    import ProductCard from './Cards/ProductCard.vue';
    export default {
        name: "ProductsList",
        components:{
            ProductCard
        },
        data(){
            return {
                products:[
                    {
                        name: "Redmi Note 13", 
                        image: "/imgs/redmi-note-13.png",
                        link: "https://mi-store.ma/tous-les-produits/redmi-note-13/",
                    },
                    {
                        name: "Redmi Note 13 Pro",
                        image: "/imgs/redmi-note-13pro.png",
                        link: "https://mi-store.ma/tous-les-produits/redmi-note-13-pro/",
                    },
                    {
                        name: "Redmi Note 13 Pro +",
                        image: "/imgs/redmi-note-13pro-plus.png",
                        link: "https://mi-store.ma/tous-les-produits/redmi-note-13-pro-5g/",
                    },
                    {
                        name: "Redmi 14C",
                        image: "/imgs/redmi-14c.png",
                        link: "https://mi-store.ma/tous-les-produits/redmi-14c/",
                    },
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
    .products-list{
        overflow: auto;
        -ms-overflow-style: none; 
        scrollbar-width: none;
    }
    .product-list::-webkit-scrollbar{
        display: hidden;
    }

</style>