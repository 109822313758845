<template>
    <div class="mt-2 px-lg-5" id="faq">
        <h2 class="faq-title mb-2" dir="rtl">الأسئلة الشائعة</h2>
        <FaqItem v-for="(faqItem, i) in faqList" :key="i"
            :faqItem="faqItem"
        />
    </div>
</template>

<script>
    import FaqItem from './FaqItem.vue';
    export default {
        name: "FaqList",
        components:{
            FaqItem
        },
        data(){
            return {
                faqList:[
                    {
                        question: "كيف يمكن تسجيل فريقي في البطولة؟",
                        answer: `يمكن تسجيل فريقك في البطولة عبر 3 مراحل:
                                <br>
                                1 - إنشاء حساب من طرف كل أعضاء الفريق داخل الموقع <a href="https://youtu.be/15q-SAFSBVI" target="_blank">(باتباع الخطوات التالية)</a>.
                                <br>
                                2 - على قائد الفريق إنشاء الفريق الخاص بكم داخل الموقع <a href="https://youtu.be/O-0hvV8WwUY" target="_blank">(باتباع الخطوات التالية)</a>.
                                <br>
                               3 - تسجيل فريقكم في البطولة من طرف قائد الفريق.
                               <br>
                                4 - و أخيرا الإنضمام ل <a href="https://discord.gg/4RuwQn2P5q" target="_blank">Server Discord</a> الخاص بالبطولة.`
                    },
                    {
                        question: "اين ستقام البطولة؟",
                        answer:`ستقام التصفيات عن بعد، و النهائي الكبير في مدينة الدار البيضاء بالضبط في صالة الألعاب <a href="https://goo.gl/maps/NFhKb79iNky8WYLBA" target="_blank">E- Blue Gaming Center</a>`
                    },
                    {
                        question: "اين يمكنني العثور على قواعد البطولة الكاملة؟",
                        answer: `يمكنك العثور على القواعد الكاملة للبطولة من خلال النقر على  <a href="https://drive.google.com/file/d/1oU29E4qVvQ3qT3ngFT1sTIHGq_MOdrTa/view?usp=sharing" target="_blank">الرابط التالي</a> ، و الرجاء قراءة هذه القواعد بعناية و الالتزام بها، و لا تقبل شكاية كل من خالفها.`
                    },
                    {
                        question: "هل يمكن استبدال اللاعبين خلال البطولة؟",
                        answer: `لا، بمجرد بدء البطولة، يجب على الفرق الحفاظ على تركيبة اللاعبين الذين سجلوا أصلاً. سيكون اللاعبون الأربعة المسجلون منذ بداية البطولة الوحيدين المسموح لهم بالمشاركة في مباريات البطولة. لن يُسمح باستبدال أي لاعب بعد بدء البطولة لضمان العدالة ونزاهة المنافسة.`
                    },
                    {
                        question: "هل من الضروري تسجيل مقاطع الفيديو ؟",
                        answer: `نعم، لضمان بيئة لعب عادلة والحد من الغش، غالبًا ما يكون من الضروري اتخاذ إجراءات لمكافحة الغش. قد تتضمن هذه الإجراءات تسجيل مقاطع الفيديو للألعاب التي يشارك فيها المشاركون. تُستخدم مقاطع الفيديو المُسجلة للتحقق من أي سلوك مشبوه أو نشاط غير مصرح به أثناء المنافسة.`
                    },
                    {
                        question: "هل من الضروري اللعب من خلال الهاتف المحمول فقط؟",
                        answer: `لا يمكن لأي مستخدم جهاز لوحي Ipad أو محاكي Emulateur المشاركة في البطولة. إذا تم اكتشاف لاعب (سيقوم المنظمون بمراقبة دورية لجميع اللاعبين، عبر طلب صورة للجهاز المستعمل أثناء اللعب)  يقوم بتسجيل الدخول إلى المباريات من جهاز محظور، فسيتم استبعاد فريقه بالكامل من البطولة و معاقبته. <br>البطولة خاصة بأصحاب الهواتف فقط !`
                    }
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
    .faq-title{
        color: var(--main-color);
        font-family: "Tajawal" !important;
        font-weight: 600;
    }
</style>