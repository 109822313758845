<template>
  <div id="app" style="background-color:#F7F7F7;">
    <MainPage></MainPage>
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    MainPage,
  },


  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer 0PbO4XPpElMHj1vg1sgGj1jPZZpVLyWXgikkxZoAaiJlZPV5PbDGerpvxtQy`;
  }





}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
 
}
</style>
