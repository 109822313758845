<template>
    <div class="bracket-container w-100 h-100">
        <!--<EliminationBracket v-if="tournmaentLoaded && bracketStructure"></EliminationBracket> -->
        <BattleRoyalBracket  v-if="tournmaentLoaded && bracketStructure"></BattleRoyalBracket>
        <div class="bracket-empty d-flex flex-column justify-content-center align-items-center px-2 w-100 h-100 mx-auto" 
            :style="{backgroundImage: `url(${require('@/assets/man-with-phone.webp')})`, 
                backgroundPosition: 'bottom',
                backgroundSize: '100% 100%'
            }"
        v-else>
            <h6 class="no-results-msg text-white font-weight-bold mb-3">La liste des participants confirmés sera disponible<br>avant le début du tournoi.
            </h6><br>
            <h6 class="no-results-msg-arabic text-white font-weight-bold" dir="rtl">قائمة المشاركين المؤكدين ستكون متاحة <br>قبل بدء البطولة.
            </h6>
        </div>
    </div>
</template>

<script>
    //import EliminationBracket from './EliminationBracket.vue';
    import BattleRoyalBracket from './BattleRoyalBracket.vue';
    export default {
        name: 'BracketViewer',
        components:{
          //  EliminationBracket,
            BattleRoyalBracket
        },
        computed:{
            bracketStructure:{
                get(){
                    return !!this.$store.state.currentTournament.tournamentItem?.bracket?.structure;
                    /*if(this.$store.state.currentTournament.tournamentItem){
                        return !!this.$store.state.currentTournament.tournamentItem.bracket.structure;
                    }
                    return false;*/
                }
            },
            tournmaentLoaded:{
                get(){
                    return !!this.$store.state.currentTournament.tournamentItem;
                }
            }
        }
    
    }
</script>
<style scoped>
    .no-results-msg{
        font-family:'Roboto', sans-serif;
        font-weight: 600;
        font-size: 20pt;

    }
    .no-results-msg-arabic{
        font-family: 'Tajawal', sans-serif;
        font-weight: 600;
        font-size: 20pt;

    }
    @media screen and (max-width: 768px){
        .bracket-empty{
            background-size: 100% 45% !important;
            background-repeat: no-repeat;
        }
        .no-results-msg, .no-results-msg-arabic{
            font-size: 12pt;
        }
        
    }
</style>