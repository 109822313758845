<template>
    <div class="product-card-wrapper py-2 px-0 mr-2 col">
        <div class="card-container d-flex flex-column justify-content-end align-items-center p-2">
            <div class="card-image p-2">
                <img alt="" class="profuct-img" :src="imageLink" height="180"/>
            </div>
            <div class="product-title-container">
                <h5 class="product-name  my-2">{{ name }}</h5>
            </div>
            <div class="card-btn-container px-2 w-100">
                <a class="btn-action px-5 py-1 d-inline-block w-100" :href="link" target="_blank">Consulter</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"ProductCard",
        props:["name", "imageLink", "link"]
    }
</script>

<style lang="css" scoped>
    .card-container{
        background-color: white;
        height: 320px;
        width: 260px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    }
    .product-name{
        color: #5F5F5F;
    }
    .btn-action{
        background-color: var(--secondary-color);
        color: white;
        text-decoration: none;
        border-radius: 50px;
    }
    .btn-action:hover{
        transform: scale(1.01);
    }
    .product-card-wrapper:last-child{
        margin-right: 0 !important;
    }
</style>